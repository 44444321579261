import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useCountryList } from "Utils/countyCodes"
import type {
  BusinessSourceOfFundsTypes,
  PEPPositionTypes,
  PEPRelationTypes,
  PurposeOfSavingTypes,
  SourceOfFundsTypes,
} from "./KYC.types"

export const useCountriesOptions = () => {
  const countryList = useCountryList()

  return countryList.map((country, i) => {
    const obj = {
      name: country[1],
      value: country[0],
      id: i,
    }

    return obj
  })
}

export const pepRelationOptions: PEPRelationTypes[] = [
  {
    value: "married",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.married",
      defaultMessage: "Maka / make",
    },
  },
  {
    value: "partner",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.partner",
      defaultMessage:
        "Partner som enligt internationell lag likställs med maka / make",
    },
  },
  {
    value: "children",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.children",
      defaultMessage: "Barn eller barns make / partner",
    },
  },
  {
    value: "parents",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.parents",
      defaultMessage: "Förälder",
    },
  },
]

export const pepPositionOptions: PEPPositionTypes[] = [
  {
    value: "head_of_state",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.headOfState",
      defaultMessage:
        "Stats- eller regeringschef, minister, eller vice/biträdande minister",
    },
  },
  {
    value: "member_of_parliament",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.memberOfParliment",
      defaultMessage: "Parlamentsledamot",
    },
  },
  {
    value: "judge",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.judge",
      defaultMessage:
        "Domare i högsta domstolen eller annat rättslig organ vilkas beslut endast undantagsvis kan överklagas",
    },
  },
  {
    value: "auditor",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.auditor",
      defaultMessage:
        "Högre tjänsteman vid revisionsmyndighet eller styrelseledamot i centralbank",
    },
  },
  {
    value: "diplomat",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.diplomat",
      defaultMessage:
        "Ambassadör, diplomatiskt sändebud, eller hög officer i försvarsmakten",
    },
  },
  {
    value: "public_company_mgmt",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.publicCompanyManagement",
      defaultMessage:
        "Person som ingår i statsägt företags förvaltnings-, lednings- eller kontrollorgan",
    },
  },
  {
    value: "international_pep",
    message: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.internationalPEP",
      defaultMessage: "Någon av befattningarna i 1 - 5 på internationell nivå",
    },
  },
]

export const purposeOfSavingOptions: PurposeOfSavingTypes[] = [
  {
    value: "economy",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.purposeOfSaving.checkbox.economy",
      defaultMessage: "Ekonomisk trygghet eller buffert",
    },
  },
  {
    value: "retirement",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.purposeOfSaving.checkbox.retirement",
      defaultMessage: "Pension",
    },
  },
  {
    value: "gift",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.purposeOfSaving.checkbox.gift",
      defaultMessage: "Gåva till närstående",
    },
  },
  {
    value: "consumption",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.purposeOfSaving.checkbox.consumption",
      defaultMessage: "Privat konsumtion",
    },
  },
  {
    value: "home_purchase",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.purposeOfSaving.checkbox.homePurchase",
      defaultMessage: "Bostadsköp",
    },
  },
  {
    value: "other",
    label: {
      id: "app.common.other",
      defaultMessage: "Annat",
    },
  },
]

export const useBusinessSourceOfFundsCheckboxes = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        value: "dividends",
        message: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.company.capital.checkbox.interestFromSavings",
          defaultMessage: "Rärnta eller utdelning från annat sparande",
        }),
      },
      {
        value: "pay",
        message: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.company.capital.checkbox.businessSurplus",
          defaultMessage: "Ã–verskott i rörelsen",
        }),
      },
      {
        value: "insurance",
        message: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.company.capital.checkbox.insurancePayouts",
          defaultMessage: "Försärkringsutbetalning",
        }),
      },
      {
        value: "real_estate_sale",
        message: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.company.capital.checkbox.realEstateSales",
          defaultMessage: "Fastighetsförsärljning",
        }),
      },
      {
        value: "company_sale",
        message: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.company.capital.checkbox.companySales",
          defaultMessage: "Företagsförsärljning",
        }),
      },
      {
        value: "other",
        message: intl.formatMessage({
          id: "app.common.other",
          defaultMessage: "Annat",
        }),
      },
    ],
    [intl]
  ) as BusinessSourceOfFundsTypes[]
}

export const sourceOfFundsOptions: SourceOfFundsTypes[] = [
  {
    value: "internal_transfer",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.internalTransfer",
      defaultMessage: "Intern Brocc-överföring",
    },
  },
  {
    value: "dividends",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.dividends",
      defaultMessage: "Ränta/Utdelningar från tidigare sparande",
    },
  },
  {
    value: "pay",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.pay",
      defaultMessage: "Lön/Pension",
    },
  },
  {
    value: "inheritence",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.inheritance",
      defaultMessage: "Arv/Gåva",
    },
  },
  {
    value: "insurance",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.insurance",
      defaultMessage: "Försäkringsutbetalning",
    },
  },
  {
    value: "gambling",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.gambling",
      defaultMessage: "Lotteri/Spel",
    },
  },
  {
    value: "home_sale",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.homeSale",
      defaultMessage: "Bostadsförsäljning",
    },
  },
  {
    value: "real_estate_sale",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.realEstateSale",
      defaultMessage: "Fastighetsförsäljning",
    },
  },
  {
    value: "company_sale",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.companySale",
      defaultMessage: "Företagsförsäljning",
    },
  },
  {
    value: "sale_of_vehicle",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.saleOfVehicle",
      defaultMessage: "Försäljning av bil, båt eller dyl",
    },
  },
  {
    value: "child_benefit",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.childBenefit",
      defaultMessage:
        "Barnbidrag, a-kassa, CSN, försäkringskassan eller annan förmån",
    },
  },
  {
    value: "other_bank",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.sourceOfIncome.checkbox.otherBank",
      defaultMessage: "Sparande i annan bank",
    },
  },
  {
    value: "other",
    label: {
      id: "app.common.other",
      defaultMessage: "Annat",
    },
  },
]

export const useCapitalOwnerOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.capitalOwnerSelect.capitalOwner",
          defaultMessage: "Kapital Ägare",
        }),
        value: "",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.capitalOwnerSelect.ownCapital",
          defaultMessage: "Egna pengar",
        }),
        value: "own_capital",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.common.other",
          defaultMessage: "Annat",
        }),
        value: "other",
        id: 3,
      },
    ],
    [intl]
  )
}

export const useWithdrawalFrequencyOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.howOften",
          defaultMessage: "Hur ofta avser du att ta ut pengar?",
        }),
        value: "",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.moreThan4TimesAMonth",
          defaultMessage: "Mer än 4 gånger i månaden",
        }),
        value: "more_than_4_times_a_month",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.upTo4TimesAMonth",
          defaultMessage: "Upp till 4 gånger i månaden",
        }),
        value: "up_to_4_times_a_month",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.monthly",
          defaultMessage: "En gång per månad",
        }),
        value: "monthly",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.quarterly",
          defaultMessage: "En gång var tredje månad",
        }),
        value: "quarterly",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.everySixMonths",
          defaultMessage: "En gång per halvår",
        }),
        value: "every_six_months",
        id: 6,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.yearly",
          defaultMessage: "En gång per år",
        }),
        value: "yearly",
        id: 7,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.rarely",
          defaultMessage: "Mer sällan än en gång per år",
        }),
        value: "rarely",
        id: 8,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.oneTime",
          defaultMessage: "En gång",
        }),
        value: "one_time",
        id: 9,
      },
    ],
    [intl]
  )
}

export const useDepositFrequencyOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.howOften",
          defaultMessage:
            "Hur ofta bedömer du att du kommer att göra insättningar till Brocc?",
        }),
        value: "",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.moreThan4TimesAMonth",
          defaultMessage: "Mer än 4 gånger i månaden",
        }),
        value: "more_than_4_times_a_month",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.upTo4TimesAMonth",
          defaultMessage: "Upp till 4 gånger i månaden",
        }),
        value: "up_to_4_times_a_month",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.monthly",
          defaultMessage: "En gång per månad",
        }),
        value: "monthly",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.quarterly",
          defaultMessage: "En gång var tredje månad",
        }),
        value: "quarterly",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.everySixMonths",
          defaultMessage: "En gång per halvår",
        }),
        value: "every_six_months",
        id: 6,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.yearly",
          defaultMessage: "En gång per år",
        }),
        value: "yearly",
        id: 7,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.rarely",
          defaultMessage: "Mer sällan än en gång per år",
        }),
        value: "rarely",
        id: 8,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.oneTime",
          defaultMessage: "Detta är en engångsinsättning",
        }),
        value: "one_time",
        id: 9,
      },
    ],
    [intl]
  )
}

export const useDepositSizeOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositSelect.howMuchDeposit",
          defaultMessage: "Hur mycket kommer du att sätta in",
        }),
        value: "",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositSelect.below10kSEK",
          defaultMessage: "Under 10 000 kr",
        }),
        value: "below10kSEK",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositSelect.10-30kSEK",
          defaultMessage: "10 000 - 30 000 kr",
        }),
        value: "10-30kSEK",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositSelect.30-50kSEK",
          defaultMessage: "30 000 - 50 000 kr",
        }),
        value: "30-50kSEK",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositSelect.50-100kSEK",
          defaultMessage: "50 000 - 100 000 kr",
        }),
        value: "50-100kSEK",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.depositSelect.above100kSEK",
          defaultMessage: "Över 100 000 kr",
        }),
        value: "above100kSEK",
        id: 6,
      },
    ],
    [intl]
  )
}

export const useInvestmentOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.howMuchInvestment",
          defaultMessage: "Hur mycket kommer du spara/investera?",
        }),
        value: "-",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.lessThan10k",
          defaultMessage: "Under 10 000 kr",
        }),
        value: "below10kSEK",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.10k-100k",
          defaultMessage: "10 000 - 100 000 kr",
        }),
        value: "10-100kSEK",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.100k-500k",
          defaultMessage: "100 000 - 500 000 kr",
        }),
        value: "100-500kSEK",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.500k-1mil",
          defaultMessage: "500 000 - 1 000 000 kr",
        }),
        value: "500-1000kSEK",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.above1milSEK",
          defaultMessage: "Över 1 000 000 kr",
        }),
        value: "above1000kSEK",
        id: 6,
      },
    ],
    [intl]
  )
}

export const useIncomeOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.inputYourIncome",
          defaultMessage: "Årsinkomst",
        }),
        value: "",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.lessThan150k",
          defaultMessage: "Under 150 000 kr per år",
        }),
        value: "below150kSEK",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.150k-300k",
          defaultMessage: "150 000 - 300 000 kr per år",
        }),
        value: "150-300kSEK",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.300k-500k",
          defaultMessage: "300 000 - 500 000 kr per år",
        }),
        value: "300-500kSEK",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.500k-1mil",
          defaultMessage: "500 000 - 1 000 000 kr per år",
        }),
        value: "500-1000kSEK",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.1mil+",
          defaultMessage: "Över 1 000 000 kr per år",
        }),
        value: "above1000kSEK",
        id: 6,
      },
    ],
    [intl]
  )
}

export const useOccupationOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.occupationSelect.chooseOccupation",
          defaultMessage: "Sysselsättning",
        }),
        value: "",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.occupationSelect.employed",
          defaultMessage: "Fast-/tillsvidareanställd",
        }),
        value: "employed",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.occupationSelect.selfEmployed",
          defaultMessage: "Egenföretagare",
        }),
        value: "self_employed",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.occupationSelect.unemployed",
          defaultMessage: "Arbetssökande",
        }),
        value: "unemployed",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.onboarding.KYC.private.occupationSelect.retired",
          defaultMessage: "Pensionär",
        }),
        value: "retired",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.common.other",
          defaultMessage: "Annat",
        }),
        value: "other",
        id: 6,
      },
    ],
    [intl]
  )
}

export const useOccupancyOptions = () => {
  const countryList = useCountryList()

  return useMemo(
    () =>
      countryList.map((country, i) => {
        const obj = {
          name: country[1],
          value: country[0],
          id: i,
        }

        return obj
      }),
    [countryList]
  )
}
