import styled from "styled-components"
import { font } from "Styles/styleGlobal"
import {
  InvoiceStatusEnum,
  StyledInvoiceStatusPropTypes,
} from "./InvoiceStatus.types"

export const StyledInvoiceStatus = styled.div<StyledInvoiceStatusPropTypes>`
  padding: 0.25em 0.5em;
  border-radius: 100px;
  max-width: 110px;
  min-width: 80px;
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: ${font.size.sm};
  gap: 5px;
  justify-content: center;
  padding: 6px 10px 6px 7px;
  color: var(--white);
  background: ${(p) => {
    switch (p.status) {
      case InvoiceStatusEnum.Paid:
        return "var(--green)"
      case InvoiceStatusEnum.Outstanding:
        return "var(--light-yellow)"
      case InvoiceStatusEnum.Overdue:
        return "var(--light-red)"
      default:
        return "var(--dark-gray)"
    }
  }};
`
