import { useMemo } from "react"
import { MessageDescriptor } from "react-intl"
import { useCountryList } from "Utils/countyCodes"

export const useOccupancyOptions = () => {
  const countryList = useCountryList()
  return useMemo(
    () =>
      countryList.map((country, i) => {
        const obj = {
          name: country[1],
          value: country[0],
          id: i,
        }

        return obj
      }),
    [countryList]
  )
}

export const livingInSweedenOptions: {
  value: boolean
  label: MessageDescriptor
}[] = [
  {
    value: true,
    label: {
      id: "app.onboarding.accept.AMLQuestions.radio.livingInSweden.yes",
      defaultMessage:
        "Ja - Jag är bosatt i Sverige och är bara skyldig att betala skatt till Sverige",
    },
  },
  {
    value: false,
    label: {
      id: "app.onboarding.accept.AMLQuestions.radio.livingInSweden.no",
      defaultMessage:
        "Nej - Jag är bosatt i annat land och/eller skattskyldig till annat land än Sverige",
    },
  },
]
